import React, {ErrorInfo, PropsWithChildren, ReactElement, ReactNode, useState} from 'react';
import './ErrorBoundary.css';
import IconButton from "../ui/IconButton/IconButton";
import Icons from "../ui/IconButton/Icons";
import {ErrorBoundary, FallbackProps} from "react-error-boundary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {captureException} from "../lib/picos/error";

export type ErrorBoundaryBaseProps = {
	// If you want to handle the error messaging yourself.  This is checked first.
	fallbackRender?: (props: FallbackProps) => ReactNode,

	// For larger areas, where a larger message is appropriate. This is checked second.
	useErrorFrame?: boolean,

	// For smallest areas, just put a little error icon with a hint
	useErrorIcon?: boolean,

	// A unique key used to determine if this bug has been logged yet
	uniqueKey: string
};

export type ErrorBoundaryProps = PropsWithChildren<ErrorBoundaryBaseProps>;

/**
 * Error handling wrapper.
 *
 * By default, an error in a component will simply show an error icon where the error occurred, with an error popup.
 *
 * You can override this behavior in two ways:
 * 1. You can ask for a standardized error frame using useErrorFrame.  This provides a nice, user-friendly section
 *    explaining that something went wrong, and that it is being looked at.
 * 2. You can provide a custom error renderer using the fallbackRender callback. This should provide a React component
 *    that will be displayed in lieu of the error icon.
 * 3. You can just let it draw an error icon with a popup message for what went wrong.
 *
 * In all cases, the error will be logged to console.error.
 *
 * Soon it will send a bug report to Intercom or some other service.
 *
 * @param {FallbackProps} props
 * @returns {ReactElement}
 * @constructor
 */
export function ErrorFrame(props: FallbackProps): ReactElement {
	const [showError, setShowError] = useState(false);

	const onViewClick = () => {
		setShowError(!showError);
	};

	return <div className='error-boundary' role='alert'>

		{/* Title */}
		<div className='error-boundary__title'>
			<IconButton type={Icons.AMBULANCE} color='maroon' label='Whoah'/>
		</div>

		{/* Standard Message */}
		<div className='error-boundary__message'>
			<p>Looks like something unexpected happened.</p>

			<p className='u-row-runin'>Please click &nbsp;
				<a href='/support'>
					<FontAwesomeIcon className='error-boundary__icon' icon={Icons.SUPPORT.reactIcon}/> here
				</a> and use the Chat talk to Support, or send an email to <a
					href='mailto:support@outlawpractice.com'>
					support@outlawpractice.com
				</a>.
			</p>
		</div>

		{/* Toggle Error Details */}
		<div className='error-boundary__show-error'>
			<IconButton type={showError ? Icons.DOUBLEDOWN : Icons.DOUBLERIGHT}
			            color='maroon' textColor='maroon'
			            label={`${showError ? 'Hide' : 'View'} Error Details`}
			            onClick={onViewClick}/>
			{showError && <div className='error-boundary__error-message'>
				<div>{props.error?.message}</div>
				<pre>{props.error?.stack}</pre>
			</div>}
		</div>
	</div>
}

/**
 * Log an error to the remote system.
 *
 * @param {Error} error
 * @param {ErrorInfo} info
 */
export function logError(error: Error, info: ErrorInfo) {
	// Report the error to Sentry
	captureException(error, {tags: ['ErrorBoundaryO'], extra: {}});

	// First, report to the console
	console.error(error);
	console.error(info);

	/*
	Now, if we have not already done so for this client, send the error report to Intercom
	See if there is an open ticket for this `${uniqueKey} ${error.toString()}`

	https://developers.intercom.com/intercom-api-reference/reference/get_tickets-id

	-- Yes?  Check to see if this customer (email) has been added to the ticket

	   -- Yes? Done here.

	   -- No? Add this user to the ticket.

		https://developers.intercom.com/intercom-api-reference/reference/put_tickets-id

	-- No?  Create a new ticket

	   -- Use the uniqueKey for the ticket ID
	   -- Add the error.toString() and info.componentStack to the ticket
	   -- Send ticket

	   https://developers.intercom.com/intercom-api-reference/reference/post_tickets

	 */
}

/**
 * Default fallback render.  Just show the error message.
 * @param {FallbackProps} props
 * @returns {ReactElement}
 */
export const defaultFallbackRender = (props: FallbackProps): ReactElement => {
	return <p className='error-boundary__error-message'>{props.error?.toString()}</p>;
}

/**
 * Render an error frame.
 *
 * @param {FallbackProps} props
 * @returns {ReactElement}
 */
export const errorFrameRender = (props: FallbackProps): ReactElement => {
	return <ErrorFrame {...props}/>;
}

/**
 *
 * @param {Error} error
 * @returns {Element}
 */
export const iconErrorRender = (error) => {
	return <div className='error-boundary--icon'>
		<FontAwesomeIcon icon={Icons.AMBULANCE.reactIcon}/>
		<div className='error-boundary--icon-error'>{error?.message || 'Unknown Error'}</div>
	</div>;
}

/**
 * Error handling wrapper.
 *
 * @param {ErrorBoundaryProps} props
 * @returns {Element}
 * @constructor
 */
function ErrorBoundaryO(props: ErrorBoundaryProps): ReactElement {
	const {uniqueKey, useErrorFrame, useErrorIcon = true} = props;

	// Use the fallbackRender passed in props if available
	let fallbackRender = props.fallbackRender;
	if (!fallbackRender && useErrorFrame) fallbackRender = errorFrameRender;
	if (!fallbackRender && useErrorIcon) fallbackRender = iconErrorRender;
	if (!fallbackRender) fallbackRender = defaultFallbackRender;

	const errorCallback = logError;

	return <ErrorBoundary fallbackRender={fallbackRender}
						  onError={errorCallback}
	>
		{props.children}
	</ErrorBoundary>
}

export default ErrorBoundaryO;