import locals from './localStorage';

import {getEnvironment} from "./get-environment";

/**
 * Logging module.
 *
 * Logs to console and Loggly based on environment (dev/test/prod).
 *
 * Special logic to stop repetitive logging to the server.
 */

let recentlyLogged: string[] = [];

export type LogFunctionTypes = 'debug' | 'log' | 'info' | 'warn' | 'error' | 'trace';

const logFunctions = ['debug', 'log', 'info', 'warn', 'error', 'trace'];

const oldDebug = console.debug;
const oldLog = console.log;
const oldInfo = console.info;
const oldWarn = console.warn;
const oldError = console.error;
const oldTrace = console.trace;

const oldFunction = [oldDebug, oldLog, oldInfo, oldWarn, oldError, oldTrace];

// Logging level, anything below this threshold is thrown away
locals.setString(locals.LOGGING_LOG_LEVEL, getEnvironment() !== 'D' ? 'warn' : 'debug');

export const debugLog = [];

function shouldLog(level: LogFunctionTypes): boolean {
	const defLevel = locals.getString(locals.LOGGING_LOG_LEVEL, 'log');
	return logFunctions.indexOf(level) >= logFunctions.indexOf(defLevel);
}

function circularFile() {
	return function (..._: any[]) {
	};
}

function productionLoggerTimeoutHandler(msg: string) {
	recentlyLogged = recentlyLogged.filter(m => m !== msg)
}

/**
 * Loggers for production
 * @param {string} level - debug, log, info, warn, error, trace
 * @returns {Function} - the new function to replace the old console logger
 */
function productionLogger(level: string) {
	return function (msg: string, opts: object) {
		const levelIndex = logFunctions.indexOf(level);
		const warnIndex = logFunctions.indexOf('warn');
		const oldFn = oldFunction[levelIndex];

		// This writes to the console
		oldFn(msg, opts);

		if (levelIndex >= warnIndex) {
			// Check to see if this message has been recently published (last 60 seconds)
			if(!recentlyLogged.some(m => m === msg)) {
				// Store this message in the log
				recentlyLogged.push(msg);

				// Remove the record after a minute
				setTimeout(productionLoggerTimeoutHandler, 60_000, msg);
			}
		}
	};
}

function loadLoggers() {
	if (!!oldTrace && !shouldLog('trace')) console.trace = circularFile();
	if (!!oldDebug && !shouldLog('debug')) console.debug = circularFile();
	if (!!oldLog && !shouldLog('log')) console.log = circularFile();
	if (!!oldInfo && !shouldLog('info')) console.info = circularFile();
	if (!!oldWarn && !shouldLog('warn')) console.warn = circularFile();
	if (!!oldError && !shouldLog('error')) console.error = circularFile();
}

// If we are on a mobile device, log to the DebugConsole component
function productionLoggers() {
	if (!!oldTrace && shouldLog('trace')) console.trace = productionLogger('trace');
	if (!!oldDebug && shouldLog('debug')) console.debug = productionLogger('debug');
	if (!!oldLog && shouldLog('log')) console.log = productionLogger('log');
	if (!!oldInfo && shouldLog('info')) console.info = productionLogger('info');
	if (!!oldWarn && shouldLog('warn')) console.warn = productionLogger('warn');
	if (!!oldError && shouldLog('error')) console.error = productionLogger('error');
}

const fn = (getEnvironment() !== 'D') ? productionLoggers : loadLoggers;

fn?.();

// Testing
// console.debug('console: debug');
// console.log('console: log');
// console.info('console: info');
// console.warn('console: warn');
// console.error('console: error');
// console.trace('console: trace');
