interface CaptureMessageOptions {
    tags: any[];
    extra: object;
}

/**
 * Capture an exception with tags.
 *
 * @param {Error} err
 * @param {CaptureMessageOptions} [options]
 */
export function captureException(err: Error, options?: CaptureMessageOptions) {
    if (import.meta.env.MODE !== 'development') {
        console.error(err, options);
        // logger.captureException(err, options);
    }
}

/**
 * Capture a message with tags.
 *
 * @param {string} message
 * @param {CaptureMessageOptions} [options]
 */
export function captureMessage(message: string, options?: CaptureMessageOptions) {
    if (import.meta.env.MODE !== 'development') {
        console.log('captureMessage', message, options)
        // logger.captureMessage(message, options);
    }
}