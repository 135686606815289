/**
 *
 * Everything in LocalStorage is stored as a string, so we need to do a little extra work to handle booleans and
 * object.
 *
 * Boolean values will be store as null (""?), "true" or "false".
 *
 * Objects will be stored with JSON.stringify and parsed with JSON.parse.
 *
 * We will store a meta-object called outlawMeta with all of the useful information about the field.
 * This object itself will be stored as a JSON.stringified string.
 */
export default class LocalStorage {
    static AUTH_ACCESS_TOKEN = 'access_token';
    static AUTH_EXPIRES_AT = 'expires_at';
    static AUTH_ID_TOKEN = 'id_token';
    static AUTH_TTL = 'outlaw.auth.ttl';
    static AUTH_USER_ID = 'outlaw.auth.user_id';
    static AUTH_FIRMS = 'outlaw.auth.firms';

    static COMMENT_SORT_ORDER = 'outlaw.comments.sort.order';

    static CONFIG_CURRENT_PAGE = 'outlaw.config.currentPage';

    static DEBUG_MODE = 'outlaw.debug.mode';

    static IDB_VERSION_RUNNING = 'outlaw.idb.version.running';
    static IDB_VERSION_CURRENT = 'outlaw.idb.version.current';

    static IS_PORTAL = 'outlaw.isPortal';

    static LOGGING_LOG_LEVEL = 'outlaw.logging.logLevel';

    static USER_FIRM_ID = 'outlaw.user.firm.id';
    static USER_FIRM = 'outlaw.user.firm';
    static USER_ID = 'outlaw.user.id';

    // New usage
    static REFRESH_TYPE = 'outlaw.polling.refreshType';

    static SOCKET_IO_DEBUG = 'debug';

    static SHOW_HELP = 'outlaw.support.showHelp';
    static DIAGNOSTICS = 'outlaw.support.diagnostics';

    static PORTAL_FIRM = 'outlaw.portal.firm';

    static INVOICE_HIDE_FINALIZED = 'outlaw.invoice.hideFinalized';

    static CASE_FILTER = 'outlaw.cases.filter';
    static LEAD_FILTER = 'outlaw.leads.filter';
    static LEADS_SHOW_CHART = 'outlaw.leads.showChart';
    static TASK_FILTER_SHOW_COMPLETED = 'outlaw.tasks.filter.showCompleted';
    static TASK_FILTER_SHOW_INCOMPLETE = 'outlaw.tasks.filter.showIncomplete';
    static TASK_FILTER_COMPLETED_LOOKBACK = 'outlaw.tasks.filter.completedLookback';
    static TASK_FILTER_RANGE = 'outlaw.tasks.filter.range';

    static TASK_SELECTED_CATEGORIES = 'outlaw.tasks.selectedCategories';
    static TASK_SELECTED_USERS = 'outlaw.tasks.selectedUsers';

    static TASK_SHOW_RELATED = 'outlaw.tasks.showRelated';
    static TASK_SHOW_DATES = 'outlaw.tasks.showDates';
    static TASK_SHOW_DISABLED_USERS = 'outlaw.tasks.showDisabled';
    static TASK_SHOW_FILTERS = 'outlaw.tasks.showFilters';
    static TASK_SHOW_GRID_NUMBERS = 'outlaw.tasks.showGridNumbers';
    static TASK_FILTER_TIME = 'outlaw.tasks.filter.time';

    static TASK_TIMERS = 'outlaw.tasks.timers';

    static VIEW_MODE = 'outlaw.tasks.viewMode';

    static CAL_VIEW = 'outlaw.cal.view';
    static CAL_LIST = 'outlaw.cal.useList';
    static CAL_DATE = 'outlaw.cal.currentDate';

    static logging = false;

    static typeBoolean = 'b';
    static typeString = 's';
    static typeNumeric = 'n';
    static typeArray = 'a';
    static typeObject = 'o';

    /**
     * Get the requested key by name. If the key does not exist, create it and return the default.
     *
     * @param {string} key - The key name used to retrieve the value in localStorage
     * @param {any} def - Default value to return if the key is not found
     * @param {string} type - The type of value to retrieve (for casting purposes)
     * @returns {*}
     */
    static _get = (key: string, def: any, type: string = LocalStorage.typeString): any => {
        if (!key) throw new Error('No key specified');

        // stored holds the result of the retrieval
        let stored = localStorage.getItem(key);

        // Log what we found
        if (LocalStorage.logging) {
            const _default = (def === undefined) ? 'undefined' : (def === null) ? 'null' : Array.isArray(def) ? '[]' : def;
            console.debug(`LocalStorage._get0(${key}), stored=${stored}, def=${_default}, type=${type}`);
        }

        // If there is a stored object
        if (stored !== null && stored !== 'null' && stored !== undefined) {
            // An item was found for the given key

            // stored object found
            let result = null;

            switch (type) {
                case LocalStorage.typeBoolean:
                    if (!['true', 'false'].includes(stored)) {
                        stored = 'false';
                    }
                    result = (stored === 'true') ? true : (stored === 'false') ? false : null;
                    break;
                case LocalStorage.typeString:
                    result = stored;
                    break;
                case LocalStorage.typeNumeric:
                    if (isNaN(Number.parseFloat(stored))) {
                        throw new Error(`${key} is not a number: ${stored}`);
                    }

                    result = parseFloat(stored);
                    break;
                case LocalStorage.typeArray:
                    // Check that the stored value is actually an array
                    if (!stored || stored === 'null') return null;
                    if (!(stored.startsWith('[') && stored.endsWith(']'))) {
                        // Not an array!
                        throw new Error(`getArray expected array for ${key}, received ||${stored}||`);
                    }
                    else result = JSON.parse(stored);

                    break;
                case LocalStorage.typeObject:
                    if (!(stored.startsWith('{') && stored.endsWith('}'))) result = null;
                    else result = JSON.parse(stored);
                    break;
                default:
                    throw new Error(`${key} - Unknown type ${JSON.stringify(type)}`);
            }

            if (LocalStorage.logging) console.debug(`LocalStorage._get1(${key},${def}) returned`, result);
            return result;
        }
        else {
            // No stored object found.
            if (def === undefined) {
                // Nothing found, no default, just return undefined
                if (LocalStorage.logging) console.debug(`LocalStorage._get2(${key}, undefined) returned`, undefined);
                return undefined;
            }
            else {
                // No stored object, but a default given.
                // Create an item with the default unless undefined.  Defaults to type string
                if (LocalStorage.logging) console.debug(`LocalStorage._get3(${key},${def}) returned`, def);

                switch (type) {
                    case LocalStorage.typeArray:
                        LocalStorage.setArray(key, def);
                        break;
                    case LocalStorage.typeObject:
                        LocalStorage.setObject(key, def);
                        break;
                    case LocalStorage.typeString:
                        LocalStorage.setString(key, def);
                        break;
                    case LocalStorage.typeBoolean:
                        LocalStorage.setBoolean(key, def);
                        break;
                    case LocalStorage.typeNumeric:
                        LocalStorage.setNumeric(key, def);
                        break;
                    default:
                        console.error('Unknown type: ' + type);
                        LocalStorage.setString(key, def);
                }

                return def;
            }
        }
    };

    /**
     * Set a value in localstorage
     * @param {string} key
     * @param {string} value
     * @param {string} type - The type of object to store.  See typeXXX constants.
     * @param {string} [prefix] - optional prefix to add
     */
    static set = (key: string, value: any, type: string = LocalStorage.typeString, prefix: string = '') => {
        let event = null;

        if (LocalStorage.logging) console.debug(`LocalStorage.set(${key}, ${value}, ${type})`);
        if (prefix) key = `${prefix}_${key}`;

        if (value == null) {
            if (LocalStorage.logging) console.debug(`Setting ${key} to ''`);
            localStorage.setItem(key, '');
            event = new CustomEvent("storageKey", {detail: {key, value: ''}});
        }
        else if (type === LocalStorage.typeArray) {
            if (!Array.isArray(value)) throw new Error('setArray expecting array, received: ' + value);
            else {
                if (LocalStorage.logging) console.debug(`Setting ${key} to ${value}`);
                localStorage.setItem(key, JSON.stringify(value));
                event = new CustomEvent("storageKey", {detail: {key, value}});
            }
        }
        else if (type === LocalStorage.typeObject) {
            if (LocalStorage.logging) console.debug(`Setting ${key} to ${JSON.stringify(value)}`);
            localStorage.setItem(key, JSON.stringify(value));
            event = new CustomEvent("storageKey", {detail: {key, value}});
        }
        else {
            if (LocalStorage.logging) console.debug(`Setting ${key} to ${value}`);
            localStorage.setItem(key, value);
            event = new CustomEvent("storageKey", {detail: {key, value}});
        }

        window.dispatchEvent(event);
    };

    /**
     * Amend key with prefix and/or firmId
     * @param {string} key
     * @param {string} [firmSpecific]
     * @param {string} [prefix]
     * @returns {string}
     */
    static amendKey(key: string, firmSpecific?: boolean, prefix?: string): string {
        const firmId = localStorage.getItem(LocalStorage.USER_FIRM_ID);
        let amendedKey = key;
        if (prefix) amendedKey = `${amendedKey}-${prefix}`;
        if (firmSpecific && firmId) amendedKey = `${amendedKey}-${firmId};`

        const baseValue = localStorage.getItem(key) ?? -100;
        const amendedValue = localStorage.getItem(amendedKey) ?? -100;

        // If there is a base value, but not an amended, one, migrate the key
        if (baseValue !== -100 && amendedValue === -100) {
            console.debug('Migrating', key);
            localStorage.setItem(amendedKey, baseValue);
            const event = new CustomEvent("storageKey", {detail: {key, value: ''}});
            window.dispatchEvent(event);
        }

        // Now return the amendedKey, if the firmId exists.
        if (firmSpecific && !firmId) {
            console.warn('Could not access current firm ID while amending key', amendedKey);
        }

        return amendedKey;
    }

    /**
     * Set a boolean value.
     *
     * @param {string} key
     * @param {boolean} value
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     */
    static setBoolean = (key: string, value: boolean, firmSpecific?: boolean, prefix?: string) => LocalStorage.set(this.amendKey(key, firmSpecific, prefix), value, LocalStorage.typeBoolean);

    /**
     * Set a string value
     * @param {string} key
     * @param {string} value
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     */
    static setString = (key: string, value: string, firmSpecific?: boolean, prefix?: string) => LocalStorage.set(this.amendKey(key, firmSpecific, prefix), value, LocalStorage.typeString);

    /**
     * Set a numeric value.
     *
     * @param {string} key
     * @param {number} value
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     */
    static setNumeric = (key: string, value: number, firmSpecific?: boolean, prefix?: string) => LocalStorage.set(this.amendKey(key, firmSpecific, prefix), value, LocalStorage.typeNumeric);

    /**
     * Set an array value.
     *
     * @param {string} key
     * @param {*[]} value
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     */
    static setArray = (key: string, value: any[], firmSpecific?: boolean, prefix?: string) => LocalStorage.set(this.amendKey(key, firmSpecific, prefix), value, LocalStorage.typeArray);

    /**
     * Set an object value.
     *
     * @param {string} key
     * @param {object} value
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     */
    static setObject = (key: string, value: object, firmSpecific?: boolean, prefix?: string) => LocalStorage.set(this.amendKey(key, firmSpecific, prefix), value, LocalStorage.typeObject);

    /**
     * Get a boolean value with a default value.
     *
     * @param {string} key
     * @param {boolean} def
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     * @returns {boolean}
     */
    static getBoolean = (key: string, def: boolean, firmSpecific?: boolean, prefix?: string): boolean => LocalStorage._get(this.amendKey(key, firmSpecific, prefix), def, LocalStorage.typeBoolean);

    /**
     * Get a boolean value with a default value.
     *
     * @param {string} key
     * @param {string} def
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     * @returns {string}
     */
    static getString = (key: string, def: string, firmSpecific?: boolean, prefix?: string): string => LocalStorage._get(this.amendKey(key, firmSpecific, prefix), def, LocalStorage.typeString);

    /**
     * Get a numeric value with a default value.
     *
     * @param {string} key
     * @param {number} def
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     * @returns {number}
     */
    static getNumeric = (key: string, def: number, firmSpecific?: boolean, prefix?: string): number => LocalStorage._get(this.amendKey(key, firmSpecific, prefix), def, LocalStorage.typeNumeric);

    /**
     * Get an array value with a default.
     *
     * @param {string} key
     * @param {*[]} def
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     * @returns {*[]}
     */
    static getArray = (key: string, def: any[], firmSpecific?: boolean, prefix?: string): any[] => LocalStorage._get(this.amendKey(key, firmSpecific, prefix), def, LocalStorage.typeArray);

    /**
     * Get an object value with a default.
     *
     * @param {string} key
     * @param {object} def
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     * @returns {object}
     */
    static getObject = (key: string, def: object, firmSpecific?: boolean, prefix?: string): object => LocalStorage._get(this.amendKey(key, firmSpecific, prefix), def, LocalStorage.typeObject);

    /**
     * Remove a key from local storage.
     *
     * @param {string} key
     * @param {boolean} [firmSpecific]
     * @param {string} [prefix]
     */
    static remove = (key: string, firmSpecific?: boolean, prefix?: string) => {
        localStorage.removeItem(LocalStorage.amendKey(key, firmSpecific, prefix));
    };

    /**
     * Returns everything in localStorage as an object
     * @param {boolean} [ignoreBackups]
     */
    static getAll = (ignoreBackups?: boolean) => {
        const result: Record<string, any> = {};

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            // Ignore null keys
            if (key === null || (ignoreBackups && key.startsWith('Backups'))) {
                // Do nothing
            }
            // Set the key on the result
            else {
                const value = localStorage.getItem(key);
                if (value === null) result[key] = null;
                else {
                    // See if the value is an object/array
                    let obj = null;
                    try {
                        obj = JSON.parse(value);
                    }
                    catch (err) {
                        // Ignore
                    }

                    result[key] = obj || value;
                }
            }
        }

        return result;
    };
}
