import UserAgentParser from "ua-parser-js";

/**
 * Check if we can get a connection to the server.
 *
 * @returns {boolean}
 */
export function isConnected(): boolean {
    return navigator.onLine && (window.__socket__?.connected);
}

/**
 * This function determines if the client is mobile, usually to see if it is a touch device.
 * Make sure that touch LAPTOPS are not included!
 *
 * Thanks to the changes in iPadOS, which now reports Safari instead of ipad, as the user agent,
 * it is no longer possible to tell if you are on an iPad without measuring the screen and trying
 * to match it to some known iPad size configurations.  Sigh.
 *
 * NOTE: Checks complete
 *
 * @return {boolean}
 */
export function isTouchDevice(): boolean {
    return isMobileView() && (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
}

/**
 * Is this any kind of Apple device (including desktop)?
 *
 * NOTE: Checks complete
 *
 * @returns {*|boolean}
 */
export function isApple() {
    return /iPhone|iPod|iPad/.test(navigator.userAgent);
}

/**
 * Are we on iOS?
 * @returns {boolean}
 */
export function isIOS(): boolean {
    return !!(new UserAgentParser().getOS().name === 'iOS' || new UserAgentParser().getBrowser().name?.includes('afari'));
}

const mobileRE = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
const notMobileRE = /CrOS/;
const tabletRE = /android|ipad|playbook|silk/i;

export function isMobile(opts: { headers?: any, ua?: any, tablet?: boolean, featureDetect?: boolean }) {
    if (!opts) opts = {};
    let ua = opts.ua
    if (!ua && typeof navigator !== 'undefined') ua = navigator.userAgent;
    if (ua && ua.headers && typeof ua.headers['user-agent'] === 'string') {
        ua = ua.headers['user-agent'];
    }
    if (typeof ua !== 'string') return false;

    let result =
        (mobileRE.test(ua) && !notMobileRE.test(ua)) ||
        (!!opts.tablet && tabletRE.test(ua));

    if (
        !result &&
        opts.tablet &&
        opts.featureDetect &&
        navigator &&
        navigator.maxTouchPoints > 1 &&
        ua.indexOf('Macintosh') !== -1 &&
        ua.indexOf('Safari') !== -1
    ) {
        result = true;
    }

    return result;
}

/**
 * Are we in the safari browser?
 *
 * @param {string} browser - the browser to test for
 * @returns {boolean}
 */
export function testForBrowser(browser: string): boolean {
    return navigator.userAgent.toLowerCase().indexOf(browser.toLowerCase()) > -1;
}

export const isChrome = () => testForBrowser('Chrome');
export const isEdge = () => testForBrowser('Edge');
export const isFirefox = () => testForBrowser('Firefox');
export const isOpera = () => testForBrowser('OPR');
export const isSafari = () => !isChrome() && !isEdge() && !isFirefox() && !isOpera() && testForBrowser('safari');

/**
 * Is this an iPhone?
 *
 * NOTE: Fully checked (only one usage)
 * @returns {*|boolean}
 */
export function isIPhone(): any | boolean {
    return /iPhone|iPod/.test(navigator.userAgent);
}

/**
 * Check if we are in mobile view, which means the width is less than the CSS max-width media query variable.
 *
 * NOTE: Fully checked
 *
 * @param {number} [overrideWidth] - Pass in a number to override window.__mobile_width__
 * @returns {boolean}
 */
export function isMobileView(overrideWidth?: number): boolean {
    const width = overrideWidth || window.__mobile_width__;
    const orientation = getDeviceOrientation();
    if (orientation === 'landscape' && window.innerWidth <= width) return true;
    else return orientation === 'portrait' && window.innerWidth <= width;
}

/**
 * Get the current orientation of the window
 *
 * @returns {'portrait'|'landscape'} - returns 'portrait' or 'landscape'
 */
export function getDeviceOrientation(): 'portrait' | 'landscape' {
    const orientationType = screen.orientation?.type;

    if (orientationType) {
        if (orientationType.includes('portrait')) return 'portrait';
        else return 'landscape';
    }
    else if (window.outerWidth / window.outerHeight > 1) return 'landscape';
    else return 'portrait';
}

/**
 * Are we working in a small screen (not just mobile, but under 1024px wide).
 *
 * NOTE: Fully checked
 *
 * @returns {boolean}
 */
export function isSmall(): boolean {
    return isMobileView() || (window.innerWidth < 1024 && getDeviceOrientation() === 'landscape');
}