'use strict';

import './lib/init';
import './lib/logger';
import React, {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {pdfjs} from 'react-pdf';
import ErrorBoundaryO from "./ErrorBoundary/ErrorBoundaryO";
import {version} from '../package.json';
import * as info from 'preval-build-info';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';
import {getEnvironment} from "./lib/get-environment";
import {CircularProgress} from "@mui/material";
import 'react-tooltip/dist/react-tooltip.css'

const fmtVersion = `Version ${version} at ${new Date(info.dateTime).toLocaleString()}`;
console.log(fmtVersion);

if (getEnvironment() !== 'D') {
	datadogLogs.init({
		clientToken: import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN,
		site: 'us5.datadoghq.com',
		forwardErrorsToLogs: true,
		forwardConsoleLogs: 'all',
		sessionSampleRate: 100,
	});

	datadogRum.init({
		applicationId: import.meta.env.VITE_APP_DATADOG_APP_ID,
		clientToken: import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN,
		site: 'us5.datadoghq.com',
		service: 'outlaw-client',
		env: import.meta.env.MODE,
		// Specify a version number to identify the deployed version of your application in Datadog
		version: fmtVersion,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
		useSecureSessionCookie: true
	});
}

const AppWrapper = lazy(() => import('./AppWrapper'));

// ComlinkWorker is defined in a vite plugin
const StorageWorker = new ComlinkWorker(new URL('./workers/storage.worker.ts', import.meta.url)); // Vite build

// Ask the user to allow permanent storage
(async () => {
	if (navigator.storage && navigator.storage.persist) {
		try {
			const isPersisted = await navigator.storage.persisted();

			if (isPersisted) console.debug('Data is already persisted');
			else {
				const result = await navigator.storage.persist();
				console.debug(`Data persisted: ${result}`);
			}
		}
		catch (err) {
			console.warn('Unable to persist storage after the user gave their assent.')
		}
	}
})();

const firstLoad = localStorage.getItem('outlaw_loaded') !== '1';

window.addEventListener("load", (event) => {
	localStorage.setItem('outlaw_loaded', '1');
});

// Detect Internet Explorer and redirect to the browser not supported page.
const msie = navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/));

// No, no, no
if (msie) {
	window.location = '/browser-not-supported.html';
}

// Set the global variable matching @media-minwidth, currently set to 550px
window.__mobile_width__ = 550;

// The storage worker

try {
	console.log('Creating storage worker');
	window.storageWorker = StorageWorker;
}
catch (err) {
	console.error('Storage worker failed to create', err);
	captureException(err);
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url,).toString();

// React 18.0+
const container = document.getElementById('root'); // If this fails, there is nothing to do

// The root is used for the main application
const root = createRoot(container);

// The hiddenRoot is used for off-screen printing (e.g. generating PDFs for an invoice
window.hiddenRoot = createRoot(document.getElementById('hidden-root'));

const outerStyle = {
	backgroundColor: 'ghostwhite',
	fontFamily: 'arial, sans-serif',
	fontSize: '1em',
	paddingTop: '3em',
	height: '100vh',
	width: '100vw',
	animation: "fadeInAnimation ease 1s",
	animationIterationCount: "1",
	animationFillMode: "forwards"
}

const innerStyle = {
	color: 'hsl(240 5.3% 26.1%)',
	display: "flex",
	flexDirection: "column",
	height: "fit-content",
	width: "fit-content",
	lineHeight: '1em',
	margin: 'auto',
	position: 'relative',
	rowGap: "2em",
}

const logoStyle = {
	fontFamily: 'Rough Riders, arial, sans-serif',
	fontSize: window.innerWidth <= 550 ? `${window.innerWidth / 12}px` : '3em',
	lineHeight: '2em',
	whiteSpace: 'wrap',
	width: 'fit-content',
	height: 'fit-content',
	margin: "1em auto 0 auto",
	textShadow: '1px 1px 3px white',
}

const progressStyle = {
	height: "fit-content", width: "fit-content", margin: "0 auto",
}

// Preload screen
function LoadingScreen() {
	const {pathname, search, href} = window.location;

	const loadingMessage = firstLoad ? 'Downloading the Outlaw Practice Application' : pathname === '/callback' ? 'Logging in' : 'Loading Application'

	return <div style={outerStyle}>
		<div style={innerStyle}>
			<div style={logoStyle}>
				Welcome to Outlaw Practice
			</div>
			<div style={progressStyle}>
				<CircularProgress/>
				<label style={{margin: 'auto 0 auto 1em', whiteSpace: 'wrap'}}
				       htmlFor='progress-bar'>{loadingMessage}
				</label>
			</div>
		</div>
	</div>
}

const client = new QueryClient(
	{
		defaultOptions: {
			queries: {
				gcTime: 60_000
			}
		}
	});

// Render the loader until the App is ready
root.render(
	<QueryClientProvider client={client}>
		<ErrorBoundaryO uniqueKey='index' useErrorFrame={true}>
			<Suspense fallback={<LoadingScreen/>}>
				<AppWrapper/>
			</Suspense>
		</ErrorBoundaryO>
	</QueryClientProvider>
	// </PersistQueryClientProvider>
);