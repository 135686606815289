export const NodeEnvironment = {
	Production: 'P',
	Beta: 'B',
	Test: 'T',
	Demo: 'DM',
	Development: 'D',
	Other: 'O'
}

export type EnvironmentType = typeof NodeEnvironment[keyof typeof NodeEnvironment];

export const NodeEnvironmentName = {
	Production: 'production',
	Beta: 'beta',
	Test: 'test',
	Demo: 'demo',
	Development: 'development',
	Other: 'other'
}

export type EnvironmentNameType = typeof NodeEnvironmentName[keyof typeof NodeEnvironmentName];

/**
 * Get a code for the current environment.
 *
 * Note that we can not set NODE_ENV in a React app the way we would in a Node app.  We would need to pass in
 * something like VITE_APP_NODE_ENV.  Instead, we use the URL of the browser to determine the environment.
 *
 * @returns {EnvironmentType}
 */
export function getEnvironment(): EnvironmentType {
	let env = 'O';

	const hostname = window.location.hostname;
	const isLocal = hostname.includes('localhost');

	if (isLocal || hostname.split('.').length === 4)
		env = 'D';
	else {
		switch (window.location.hostname) {
			case 'app.outlawpractice.com':
				return 'P';
			case 'app-beta.outlawpractice.com':
				return 'B';
			case 'app-test.outlawpractice.com':
				return 'T';
			case 'app-demo.outlawpractice.com':
				return 'DM';
			case 'dev.outlawpractice.com':
				return 'D';
			default:
				console.warn('URL does not match a known environment, switching to development.', window.location.hostname);
				return 'D';
		}
	}

	return env;
}

export function getEnvironmentName(): EnvironmentNameType {
	switch (getEnvironment()) {
		case 'P':
			return 'production';
		case 'B':
			return 'beta';
		case 'DM':
			return 'demo';
		case 'T':
			return 'test';
		default:
			return 'development'
	}
}