import {isTouchDevice} from "./picos/browserFns";
import Buffer from "safe-buffer";

Object.defineProperty(window, 'Buffer', {
    value: Buffer.Buffer,
    writable: true,
});

// Is this a mobile device?
window.__is_mobile__ = isTouchDevice();
